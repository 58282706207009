export const darkTheme = {
    bg: "#151515",
    bgLight: "#1F1F1F",
    primary: "#B31B25",
    text_primary: "#F8F8F8",
    text_secondary: "#DBDBDB",
    text_skills: "#B8B8B8",
    card: "#1A1A1A",
    card_light: "#252525",
    button: "#A61922",
    white: "#FFFFFF",
    black: "#000000",
    colored_detail: "#943338",
    highlighted_svg: "#8B2F33",
    buttonGradient: "linear-gradient(180deg, #B31B25 0%, #8B2F33 100%)"
};

export const lightTheme = {
    bg: "#F8F8F8",
    bgLight: "#FFFFFF",
    primary: "#B31B25",
    text_primary: "#1A1A1A",
    text_secondary: "#404040",
    text_skills: "#8B2F33",
    card: "#FFFFFF",
    card_light: "#F5F5F5",
    button: "#A61922",
    white: "#FFFFFF",
    black: "#000000",
    colored_detail: "#943338",
    highlighted_svg: "#8B2F33",
    buttonGradient: "linear-gradient(180deg, #B31B25 0%, #8B2F33 100%)"
};

